@tailwind base;
@tailwind components;
@tailwind utilities;


.contact {
    background-image: url('./asset//contactbgdesktop.png');
    background-size: cover;
}

.slick-track {
    display: flex;
}

.slick-arrow {
    display: none !important;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 12px !important;
}
.slick-dots li {
	padding: 0px 15px !important;
}

.overflow-hidden {
    overflow-x: hidden;
}

.bgimage {
    background-image: url('./asset/knowproperty.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    opacity: 0.2;
}
@keyframes shine {
    0% {
        background-position: -100px;
    }
    25%{
        background-position: 0px;
    }
    50%{
        background-position: 100px;
    }
    100% {
        background-position: 200px;
    }
}
.ReactFlagsSelect-module_selectBtn__19wW7{
    width: fit-content !important;
    margin: 0 auto;
}

.input-focused {
    border: 1px solid transparent;
    background-image: linear-gradient(black, black),
                      linear-gradient(45deg, #f3ec78, #af4261);
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: shine 1.5s linear infinite;
}
